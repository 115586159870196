import SaasHeader from 'pwasaasComponent/SaasHeader/SaasHeader.component';

import './SaasHeader.override.manual.style';

/** @namespace Bierlokaal/Component/SaasHeader/Component/SaasHeaderComponent */
export class SaasHeaderComponent extends SaasHeader {
    /** TODO: Add setting to enable/disable mobile usps?? */
    renderMobileHeaderUsp() {
        return null;
    }
}

export default SaasHeaderComponent;
